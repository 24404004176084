import { SyncGroup, SyncRecord } from "../../api/sync";
import {
  Button,
  CardHeader,
  Checkbox,
  Container,
  List,
  ListItem,
  Modal,
  TableHead,
  Typography,
} from "@mui/material";
import classes from "../duplicates/css/duplicates.module.css";
import Card from "@mui/material/Card/Card";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { encodeValue, toReadableText } from "../utils/utils";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  SearchOnGoogleBtn,
  SearchOnLinkedinBtn,
} from "../duplicates/DuplicateMergeModal";

const SyncDetailsModalColumnHeader = (
  props: Readonly<{ system_name: string; sync_record: SyncRecord }>
) => {
  return (
    <TableCell
      style={{
        padding: "2px",
        minWidth: "300px",
        height: "100%",
        position: "relative",
        paddingBottom: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "0.25rem",
          alignItems: "left",
        }}
      >
        <List>
          <ListItem style={{ padding: 0 }}>
            <Typography
              variant={"h5"}
              style={{
                fontWeight: "bold",
                padding: 4,
                marginTop: 3.5,
              }}
            >
              {props.system_name}
            </Typography>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Tooltip title={"View in CRM"}>
                <LaunchIcon
                  style={{
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(props.sync_record.url, "_blank")}
                />
              </Tooltip>
              <Divider orientation="vertical" variant="middle" flexItem />
              <SearchOnLinkedinBtn
                onClick={() =>
                  window.open(
                    `https://www.linkedin.com/search/results/all/?keywords=${encodeValue(
                      props.sync_record.company
                    )} : ${encodeValue(
                      props.sync_record.first_name
                    )} ${encodeValue(props.sync_record.last_name)}`,
                    "_blank"
                  )
                }
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <SearchOnGoogleBtn
                onClick={() =>
                  window.open(
                    `https://www.google.com/search?q=${encodeValue(
                      props.sync_record.company
                    )} ${encodeValue(
                      props.sync_record.first_name
                    )} ${encodeValue(props.sync_record.last_name)}`,
                    "_blank"
                  )
                }
              />
            </div>
          </ListItem>
        </List>
      </div>
    </TableCell>
  );
};

export interface SyncDetailsModelButton {
  text: string;
  icon: object;
  color: string;
  onClick: () => void;
}

export const SyncDetailsModal = (
  props: Readonly<{
    modalOpen: boolean;
    onModalClose(success: boolean): void;
    syncGroup: SyncGroup;
    modelButtons?: SyncDetailsModelButton[];
    syncFieldMatching?: string[];
    syncGroupMatchingFields?: object;
    setSyncGroupMatchingFields?(params): void;
  }>
) => {
  const {
    modalOpen,
    onModalClose,
    syncGroup,
    modelButtons,
    syncFieldMatching,
    syncGroupMatchingFields,
    setSyncGroupMatchingFields,
  } = props;

  return (
    <Modal
      open={modalOpen}
      onClose={() => onModalClose(false)}
      className={classes.dupeModal}
    >
      <Container maxWidth={"md"} style={{ padding: "0px" }}>
        <Card>
          <CardHeader
            title={"Sync Group"}
            titleTypographyProps={{ variant: "h3" }}
            avatar={
              <Avatar
                variant={"square"}
                style={{ width: "48px", height: "48px" }}
                alt={"cirrom_logo"}
                src={"/Cirrom-C.webp"}
              />
            }
          />
          <Divider />
          <Container
            style={{
              maxHeight: (window.outerHeight * 0.7).toString() + "px",
              overflow: "overlay",
            }}
          >
            {syncGroup && (
              <TableContainer style={{ padding: "2px" }}>
                <Table size={"small"} style={{ overflow: "scroll" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <SyncDetailsModalColumnHeader
                        system_name={syncGroup.system_a_name}
                        sync_record={syncGroup.system_a_record}
                      />
                      <SyncDetailsModalColumnHeader
                        system_name={syncGroup.system_b_name}
                        sync_record={syncGroup.system_b_record}
                      />
                    </TableRow>

                    {Object.keys(syncGroup.system_a_record)
                      .filter((key) => ["url"].indexOf(key) == -1)
                      .map((key: string) => {
                        return (
                          <TableRow key={syncGroup.sync_group_id + "_" + key}>
                            <TableCell
                              style={{
                                padding: "2px",
                                minWidth: "170px",
                                height: "100%",
                                position: "relative",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {toReadableText(key)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "2px",
                                minWidth: "300px",
                                height: "100%",
                                position: "relative",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                              }}
                            >
                              {syncFieldMatching ? (
                                <Checkbox
                                  disabled={
                                    syncGroupMatchingFields &&
                                    !(key in syncGroupMatchingFields)
                                  }
                                  checked={
                                    syncGroupMatchingFields &&
                                    syncGroupMatchingFields[key] === "a"
                                  }
                                  onChange={(event) => {
                                    setSyncGroupMatchingFields({
                                      ...syncGroupMatchingFields,
                                      [key]: event.target.checked ? "a" : null,
                                    });
                                  }}
                                />
                              ) : null}
                              {syncGroup.system_a_record[key]}
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "2px",
                                minWidth: "300px",
                                height: "100%",
                                position: "relative",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                              }}
                            >
                              {syncFieldMatching ? (
                                <Checkbox
                                  disabled={
                                    syncGroupMatchingFields &&
                                    !(key in syncGroupMatchingFields)
                                  }
                                  checked={
                                    syncGroupMatchingFields &&
                                    syncGroupMatchingFields[key] === "b"
                                  }
                                  onChange={(event) => {
                                    setSyncGroupMatchingFields({
                                      ...syncGroupMatchingFields,
                                      [key]: event.target.checked ? "b" : null,
                                    });
                                  }}
                                />
                              ) : null}
                              {syncGroup.system_b_record[key]}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableHead>
                </Table>
              </TableContainer>
            )}
          </Container>
          <Container
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "10px",
              padding: "10px",
            }}
          >
            {modelButtons.map((modelButton) => {
              return (
                <Button
                  variant="contained"
                  color={modelButton.color}
                  startIcon={modelButton.icon}
                  size="small"
                  onClick={modelButton.onClick}
                >
                  {modelButton.text}
                </Button>
              );
            })}
          </Container>
        </Card>
      </Container>
    </Modal>
  );
};
