import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import classes from "../duplicates/css/duplicates.module.css";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { SyncGroup } from "../../api/sync";

/**
 * Common constructor for Sync Table
 * Used in all the user pages showing a grid with Sync information
 *  */
export const SyncTable = React.memo(function SyncTable(props: {
  syncGroups: SyncGroup[];
  onRowSelectionChanged?: (params) => void;
  onRowClicked?: (params) => void;
}) {
  const { syncGroups, onRowSelectionChanged, onRowClicked } = props;

  const [gridSelectedSyncGroups, setGridSelectedSyncGroups] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (onRowSelectionChanged) {
      onRowSelectionChanged(gridSelectedSyncGroups);
    }
  }, [gridSelectedSyncGroups]);

  const getUnpackedData = useCallback((packedData: SyncGroup[]) => {
    const unpackedSyncRowData = [];
    packedData.forEach((sync_group, index) => {
      unpackedSyncRowData.push({
        header: true,
        headerIndex: index,
        group_id: sync_group.sync_group_id,
        match_source: sync_group.match_source,
        sync_group: sync_group,
      });
      let subIndex = 0;
      unpackedSyncRowData.push({
        header: false,
        headerIndex: 0,
        system_name: sync_group.system_a_name,
        company: sync_group.system_a_record.company,
        first_name: sync_group.system_a_record.first_name,
        last_name: sync_group.system_a_record.last_name,
        primary_email: sync_group.system_a_record.primary_email,
        additional_attribute_link_id:
          sync_group.system_a_record.other_system_id,
      });
      unpackedSyncRowData.push({
        header: false,
        headerIndex: 1,
        system_name: sync_group.system_b_name,
        company: sync_group.system_b_record.company,
        first_name: sync_group.system_b_record.first_name,
        last_name: sync_group.system_b_record.last_name,
        primary_email: sync_group.system_b_record.primary_email,
        additional_attribute_link_id:
          sync_group.system_b_record.other_system_id,
      });
    });
    return unpackedSyncRowData;
  }, []);

  const VirtuosoTableComponents: TableComponents<any> = useMemo(() => {
    return {
      Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
      )),
      Table: (props) => (
        <Table
          {...props}
          sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
        />
      ),
      TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody
          {...props}
          ref={ref}
          style={{ zIndex: "auto", position: "sticky", top: 0 }}
        />
      )),
      TableRow: ({ item: _item, ...props }) => (
        <TableRow {...props} className={classes.duplicateTableGroup} />
      ),
      TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
      )),
    };
  }, []);

  const fixedHeaderContent = () => {
    return (
      <TableRow style={{ background: "white" }}>
        <TableCell style={{ width: "50px" }}>
          <Checkbox
            color="primary"
            style={{ padding: "5px" }}
            onChange={(event) => {
              if (event.currentTarget.checked) {
                setGridSelectedSyncGroups(
                  syncGroups.map((syncGroup) => syncGroup.sync_group_id)
                );
              } else {
                setGridSelectedSyncGroups([]);
              }
            }}
            checked={gridSelectedSyncGroups.length == syncGroups.length}
          />
        </TableCell>
        <TableCell key={"sync_group_id"}>Sync Group</TableCell>
        <TableCell key={"company"}>Company</TableCell>
        <TableCell key={"first_name"}>First Name</TableCell>
        <TableCell key={"last_name"}>Last Name</TableCell>
        <TableCell key={"primary_email"}>Primary Email</TableCell>
        <TableCell key={"additional_attribute_link_id"}>Sync Link ID</TableCell>
      </TableRow>
    );
  };

  const rowContent = useCallback(
    (_index: number, row) => {
      return (
        <React.Fragment>
          {row.header ? (
            <>
              <TableCell style={{ width: "50px" }}>
                <Checkbox
                  color="primary"
                  style={{ padding: "5px" }}
                  onChange={(event) => {
                    if (
                      event.currentTarget.checked &&
                      gridSelectedSyncGroups.indexOf(row.group_id) < 0
                    ) {
                      setGridSelectedSyncGroups([
                        ...gridSelectedSyncGroups,
                        row.group_id,
                      ]);
                    } else if (
                      !event.currentTarget.checked &&
                      gridSelectedSyncGroups.indexOf(row.group_id) >= 0
                    ) {
                      setGridSelectedSyncGroups(
                        gridSelectedSyncGroups.filter(
                          (syncGroupId) => syncGroupId != row.group_id
                        )
                      );
                    }
                  }}
                  checked={gridSelectedSyncGroups.indexOf(row.group_id) >= 0}
                />
              </TableCell>
              <TableCell
                className={classes.duplicateTableRow}
                onClick={() => {
                  onRowClicked(row.sync_group);
                }}
              >
                {row.group_id}
              </TableCell>
              <TableCell
                className={classes.duplicateTableRow}
                onClick={() => {
                  onRowClicked(row.sync_group);
                }}
              >
                {row.match_source}
              </TableCell>
            </>
          ) : (
            <>
              <TableCell className={classes.duplicateTableRow} />
              <TableCell className={classes.duplicateTableRow}>
                <div className={classes.duplicateTableChildCell}>
                  <SubdirectoryArrowRightIcon style={{ width: "15px" }} />
                  {row.system_name}
                </div>
              </TableCell>
              <TableCell className={classes.duplicateTableRow}>
                {row.company}
              </TableCell>
              <TableCell className={classes.duplicateTableRow}>
                {row.first_name}
              </TableCell>
              <TableCell className={classes.duplicateTableRow}>
                {row.last_name}
              </TableCell>
              <TableCell className={classes.duplicateTableRow}>
                {row.primary_email}
              </TableCell>
              <TableCell className={classes.duplicateTableRow}>
                {row.additional_attribute_link_id}
              </TableCell>
            </>
          )}
        </React.Fragment>
      );
    },
    [syncGroups, gridSelectedSyncGroups]
  );

  return (
    <>
      <TableContainer className={classes.duplicatesTableContainer}>
        <TableVirtuoso
          components={VirtuosoTableComponents}
          data={getUnpackedData(syncGroups)}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </TableContainer>
    </>
  );
});
